<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'addNewWorkflowTemplate'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-subheader v-t="'basicData'" />
        <v-text-field
          v-model="workflowData.title"
          outlined
          dense
          :rules="[validators.required]"
          :label="$t('title')"
          :placeholder="$t('title')"
          hide-details="auto"
          maxlength="100"
          class="mb-3"
        ></v-text-field>

        <v-textarea
          v-model="workflowData.internalNote"
          outlined
          auto-grow
          :label="$t('internalNote')"
          :placeholder="$t('internalNote')"
          rows="4"
          maxlength="1000"
          hide-details="auto"
          class="mb-3"
        />

        <v-row>
          <v-col cols="12">
            <draggable
              v-model="workflowData.stepsTemplate"
              v-bind="dragOptions"
              :scroll-sensitivity="350"
              :force-fallback="true"
              @end="onDragEnd"
              @start="onDragStart"
            >
              <div v-for="(step, index) in workflowData.stepsTemplate" :key="`Steps-template--${index}`" class="py-3">
                <v-card outlined class="card--border-primary my-handle">
                  <v-card-title>
                    <v-row>
                      <v-col cols="12" md="7">
                        <v-text-field
                          v-model="step.name"
                          :label="$t('name')"
                          :placeholder="$t('name')"
                          outlined
                          dense
                          maxlength="100"
                          hide-details="auto"
                          :rules="[validators.required]"
                        >
                          <template #prepend>
                            <v-icon class="cursor-pointer" color="primary">{{ icons.mdiDrag }}</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model="step.stepDayLimit"
                          :label="$t('dayLimit')"
                          :placeholder="$t('dayLimit')"
                          outlined
                          dense
                          type="number"
                          min="0"
                          max="365"
                          hide-details="auto"
                          :rules="[validators.minPositiveValue, validators.maxDayValueWithEmptyValue]"
                        >
                          <template #append-outer>
                            <v-tooltip top transition="scroll-y-transition" open-delay="150">
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  color="error"
                                  x-small
                                  v-bind="attrs"
                                  @click="deleteStep(workflowData.stepsTemplate, index)"
                                  v-on="on"
                                >
                                  <v-icon class="me-2" size="16">
                                    {{ icons.mdiTrashCanOutline }}
                                  </v-icon>
                                  <span v-t="'delete'" />
                                </v-btn>
                              </template>
                              <span v-t="'deleteStep'" class="text-xs" />
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="step.description"
                          outlined
                          auto-grow
                          :label="$t('description')"
                          :placeholder="$t('description')"
                          rows="2"
                          hide-details="auto"
                          maxlength="1000"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </div>
            </draggable>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" block @click="addStep(workflowData.stepsTemplate)">Add step</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :disabled="!valid || workflowData.stepsTemplate.length === 0"
              block
              :loading="loading"
            >
              {{ $t('add') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="resetWorkflowData" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { required } from '@core/utils/validation'

import { mdiClose, mdiTrashCanOutline, mdiDrag } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'
import draggable from 'vuedraggable'
import useDraggableOptions from '@/plugins/draggable'
import workflowTemplateUtils from '@/utils/workflowTemplateUtils'

export default {
  components: {
    draggable,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { dragOptions, onDragStart, onDragEnd } = useDraggableOptions()

    const { addStep, deleteStep } = workflowTemplateUtils()

    const blankWorkflowData = {
      title: '',
      internalNote: '',
      stepsTemplate: [],
    }

    const { createWorkflowTemplate } = useActions('jobs', ['createWorkflowTemplate'])

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const workflowData = ref(_.cloneDeep(blankWorkflowData))

    const resetWorkflowData = () => {
      workflowData.value = _.cloneDeep(blankWorkflowData)
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true
        await createWorkflowTemplate(workflowData.value)
        loading.value = false
        resetWorkflowData()
        emit('refetch-data')
      } else {
        validate()
      }
    }

    return {
      onDragStart,
      onDragEnd,
      resetWorkflowData,
      addStep,
      deleteStep,
      onSubmit,

      dragOptions,
      form,
      loading,
      workflowData,
      valid,

      validators: { required },
      icons: {
        mdiClose,
        mdiTrashCanOutline,
        mdiDrag,
      },
    }
  },
}
</script>
