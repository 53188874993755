import { ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function useWorkflowList() {
  const workflowTemplates = ref([])

  const tableColumns = [
    { text: 'title', value: 'title', class: 'ps-7 text-uppercase' },

    { text: 'internalNote', value: 'internalNote', sortable: false, width: '40%', class: 'text-uppercase' },
    { text: 'stepsCount', value: 'stepsCount', align: 'center', class: 'text-uppercase' },
    {
      text: 'actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      class: 'text-uppercase',
    },
  ]

  const searchQuery = ref('')
  const totalWorkflowTemplateListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  })
  const userTotalLocal = ref([])
  const overview = ref([])

  const { getWorkflowTemplates } = useActions('jobs', ['getWorkflowTemplates'])

  // fetch data
  const fetchWorkflowTemplates = async () => {
    loading.value = true
    const { data, success } = await getWorkflowTemplates({
      search: searchQuery.value,
      page: options.value.page,
      pageSize: options.value.itemsPerPage,
      ordering: options.value.sortBy.map((el, index) => {
        const parsedEl = el.replaceAll('.', '__').replace('stepsCount', 'steps_count')

        return `${options.value.sortDesc[index] ? '-' : ''}${parsedEl}`
      }),
    })
    if (success) {
      if (Object.hasOwnProperty.bind(data)('results') && Object.hasOwnProperty.bind(data)('count')) {
        workflowTemplates.value = data.results
        totalWorkflowTemplateListTable.value = data.count
      } else {
        workflowTemplates.value = data
        totalWorkflowTemplateListTable.value = data.length
      }
      loading.value = false
    }
  }

  const timer = ref(null)
  const delaySearchHandler = async () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(async () => {
      await fetchWorkflowTemplates()
    }, 1000)
  }

  watch([options], async () => {
    await fetchWorkflowTemplates()
  })

  return {
    fetchWorkflowTemplates,
    delaySearchHandler,

    tableColumns,
    searchQuery,
    totalWorkflowTemplateListTable,
    loading,
    options,
    userTotalLocal,
    overview,
    workflowTemplates,
  }
}
