var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('workflow-template-list-add-new',{on:{"refetch-data":_vm.fetchWorkflowTemplates},model:{value:(_vm.isAddNewUserSidebarActive),callback:function ($$v) {_vm.isAddNewUserSidebarActive=$$v},expression:"isAddNewUserSidebarActive"}}),_c('h2',{directives:[{name:"t",rawName:"v-t",value:('workflowTemplates'),expression:"'workflowTemplates'"}],staticClass:"text-2xl font-weight-semibold mb-4"}),_c('v-card',{staticClass:"mb-12"},[_c('v-card-title',{directives:[{name:"t",rawName:"v-t",value:('searchAndFilter'),expression:"'searchAndFilter'"}]}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":_vm.$t('search'),"outlined":"","hide-details":"","dense":""},on:{"keydown":_vm.delaySearchHandler},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewUserSidebarActive = !_vm.isAddNewUserSidebarActive}}},[_c('v-icon',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',{directives:[{name:"t",rawName:"v-t",value:('addNewWorkflowTemplate'),expression:"'addNewWorkflowTemplate'"}]})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.workflowTemplates,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalWorkflowTemplateListTable,"footer-props":{
        'items-per-page-text': _vm.$t('rowsPerPage'),
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"650px","persistent":""},model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c('v-card',{staticClass:"pa-sm-10 pa-3"},[_c('v-card-title',{staticClass:"justify-center text-h5"},[_vm._v("Delete workflow template ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.$t('confirmationModalUndoneQuestion')))])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center mt-3",attrs:{"cols":"12"}},[_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('discard'),expression:"'discard'"}],staticClass:"me-3",attrs:{"outlined":""},on:{"click":_vm.discardDelete}}),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.confirmDelete}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"header.title",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.internalNote",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.stepsCount",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, { count: '' }))+" ")]}},{key:"header.actions",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'apps-workflow-template-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]}},{key:"item.internalNote",fn:function(ref){
      var item = ref.item;
return [_c('v-clamp',{attrs:{"autoresize":"","max-lines":3}},[_vm._v(_vm._s(item.internalNote))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipActivator){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},'v-btn',tooltipActivator.attrs,false),tooltipActivator.on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('delete'),expression:"'delete'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.duplicateHandler(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentDuplicate)+" ")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('duplicate'),expression:"'duplicate'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'apps-workflow-template-view', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('view'),expression:"'view'"}]})])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }