<template>
  <div id="user-list">
    <workflow-template-list-add-new
      v-model="isAddNewUserSidebarActive"
      @refetch-data="fetchWorkflowTemplates"
    ></workflow-template-list-add-new>

    <h2 v-t="'workflowTemplates'" class="text-2xl font-weight-semibold mb-4" />
    <v-card class="mb-12">
      <v-card-title v-t="'searchAndFilter'" />
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive">
            <v-icon class="me-2">{{ icons.mdiPlus }}</v-icon>
            <span v-t="'addNewWorkflowTemplate'" />
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="workflowTemplates"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalWorkflowTemplateListTable"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
        }"
      >
        <template #[`top`]>
          <v-dialog v-model="isDialogVisible" max-width="650px" persistent>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title class="justify-center text-h5">Delete workflow template </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex align-center justify-center">{{
                    $t('confirmationModalUndoneQuestion')
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined class="me-3" @click="discardDelete" />
                    <v-btn color="error" @click="confirmDelete"> Delete </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`header.title`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.internalNote`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.stepsCount`]="{ header }">
          {{ $t(header.text, { count: '' }) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.title`]="{ item }">
          <div class="d-flex flex-column ms-3">
            <router-link
              :to="{ name: 'apps-workflow-template-view', params: { id: item.id } }"
              class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.title }}
            </router-link>
          </div>
        </template>

        <template #[`item.internalNote`]="{ item }">
          <v-clamp autoresize :max-lines="3">{{ item.internalNote }}</v-clamp>
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip bottom>
              <template #activator="tooltipActivator">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="tooltipActivator.attrs"
                  v-on="tooltipActivator.on"
                  @click="deleteItem(item.id)"
                >
                  <v-icon size="18">
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'delete'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" @click="duplicateHandler(item.id)">
                  <v-icon size="18">
                    {{ icons.mdiContentDuplicate }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'duplicate'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  :to="{ name: 'apps-workflow-template-view', params: { id: item.id } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'view'" />
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiTrashCanOutline, mdiEyeOutline, mdiContentDuplicate } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import VClamp from 'vue-clamp'

import WorkflowTemplateListAddNew from './WorkflowListAddNew.vue'
import useWorkflowList from './useWorkflowList'
import workflowTemplateUtils from '@/utils/workflowTemplateUtils'

export default {
  components: {
    VClamp,
    WorkflowTemplateListAddNew,
  },
  setup() {
    const { duplicateWorkflowTemplate, deleteWorkflowTemplate, fetchRecruitersChoices } = workflowTemplateUtils()

    const isDialogVisible = ref(false)
    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchWorkflowTemplates,
      delaySearchHandler,

      tableColumns,
      searchQuery,
      totalWorkflowTemplateListTable,
      loading,
      options,
      userTotalLocal,
      overview,
      workflowTemplates,
    } = useWorkflowList()

    const duplicateHandler = async (workflowTemplateId) => {
      await duplicateWorkflowTemplate(workflowTemplateId)
      await fetchWorkflowTemplates()
    }

    onMounted(async () => {
      await fetchRecruitersChoices()
    })

    const itemToDeleteId = ref(null)
    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value !== null) {
        await deleteWorkflowTemplate(itemToDeleteId.value)
        await fetchWorkflowTemplates()
      }
      isDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDialogVisible.value = false
    }

    return {
      fetchWorkflowTemplates,
      duplicateHandler,
      deleteItem,
      confirmDelete,
      discardDelete,
      delaySearchHandler,

      isDialogVisible,
      tableColumns,
      searchQuery,
      totalWorkflowTemplateListTable,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      overview,
      workflowTemplates,

      // icons
      icons: {
        mdiPlus,
        mdiTrashCanOutline,
        mdiEyeOutline,
        mdiContentDuplicate,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
